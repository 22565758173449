var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content has_breadcrumb"},[_c('div',{staticClass:"connections-content"},[_c('edoovo-table',{ref:"connectionsTable",attrs:{"data":_vm.tableData,"default-value":"-","rowKey":"id","row-class-name":_vm.tableRowClassName}},[_c('edoovo-table-column',{attrs:{"size":"L","label":"Children","prop":"nickname","tooltip":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[(row.photo)?_c('head-img',{staticClass:"table-cell-img",attrs:{"src":row.photo,"line-height":"48"},on:{"click":function () {}}}):_c('div',{staticClass:"table-cell-img"},[_c('span',{staticClass:"textPhoto"},[_vm._v(_vm._s(_vm.name(row.firstName,row.lastName)))])]),_c('label',{staticClass:"table-cell-name"},[_vm._v(_vm._s(row.firstName.charAt(0).toUpperCase() + row.firstName.slice(1))+" "+_vm._s(row.lastName.charAt(0).toUpperCase() + row.lastName.slice(1)))])],1)]}}])}),_c('edoovo-table-column',{attrs:{"size":"XL","label":"schools","prop":"schoolImage","tooltip":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('img',{staticClass:"table-cell-img school-img",attrs:{"src":row.schoolImage}}),_c('label',{staticClass:"table-cell-name",attrs:{"title":((row.companyName) + " @ " + (row.schoolName))}},[_vm._v(_vm._s(((row.companyName) + " @ " + (row.schoolName))))])])]}}])}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Status","prop":"parentStatus","tooltip":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.status=='Withdrawn'?'Disconnected':'Connected')+" ")]}}])}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Date","prop":"modifyTime","tooltip":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('label',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.dateFormat(row.modifyTime)))])]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }