<!-- parents-info-page  -->
<template>
  <div class="content has_breadcrumb">
    <!-- 表格 -->
    <div class="connections-content">
      <edoovo-table
        ref="connectionsTable"
        :data="tableData"
        default-value="-"
        rowKey="id"
        :row-class-name="tableRowClassName"
      >
        <edoovo-table-column
          size="L"
          label="Children"
          prop="nickname"
          :tooltip="false"
          v-slot="{ row }"
        >
          <div>
            <head-img
              class="table-cell-img"
              :src="row.photo"
              line-height="48"
              @click="() => {}"
			  v-if='row.photo'
            ></head-img>
			<div class="table-cell-img" v-else>
				<span class="textPhoto" >{{ name(row.firstName,row.lastName) }}</span>
			</div>
            <label class="table-cell-name">{{row.firstName.charAt(0).toUpperCase() + row.firstName.slice(1)}} {{row.lastName.charAt(0).toUpperCase() + row.lastName.slice(1)}}</label>
          </div>
        </edoovo-table-column>
        <edoovo-table-column
          size="XL"
          label="schools"
          prop="schoolImage"
          :tooltip="false"
          v-slot="{ row }"
        >
          <div>
            <img :src="row.schoolImage" class="table-cell-img school-img" />
            <label class="table-cell-name" :title=" `${row.companyName} @ ${row.schoolName}`">{{
              `${row.companyName} @ ${row.schoolName}`
            }}</label>
          </div>
        </edoovo-table-column>
        <edoovo-table-column
          size="M"
          label="Status"
          prop="parentStatus"
          :tooltip="false"
		    v-slot="{ row }"
        >
		{{row.status=='Withdrawn'?'Disconnected':'Connected'}}
		</edoovo-table-column>
        <edoovo-table-column
          size="M"
          label="Date"
          prop="modifyTime"
          :tooltip="false"
		     v-slot="{ row }"
        >
		 <label class="table-cell-name">{{ dateFormat(row.modifyTime)  }}</label>
		</edoovo-table-column>
      </edoovo-table>
    </div>
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
import moment from 'moment';
export default {
  name: "parentsInfo",
  data() {
    return {
      menuDatas: [
        {
          name: "Parent Info ",
          key: "parentsInfo",
          active: true,
          children: [],
        },
        {
          name: "Connections",
          key: "connections",
          children: [],
        },
      ],
      activeIndex: "parentsInfo",
      borderLessData: [
        { label: "First Name", value: "xxx xxx" },
        { label: "Last Name", value: "xxx xxx" },
        { label: "Email", value: "xxx" },
        { label: "Phone", value: "xxxxxx" },
        { label: "Job title", value: "xxx" },
        { label: "Working type", value: "xxx" },
      ],
      tableData: [],
      avatar:
        "https://edoovo-hk.oss-accelerate.aliyuncs.com/uat_avatar/2020-09-11/bd537d11fcbf4c398c047d377a62d681-130423902631638220815238764789965073975.png",
      parentUserId: sessionStorage.getItem("parentUserId"),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
	name() {
		return function(firstName,lastName){
			console.log(firstName)
			if (/[\u4e00-\u9fa5]/gi.test(firstName)) {
			  const allName = `${lastName}${firstName}`;
			  if (allName.length <= 2) {
			    return allName;
			  }
			  return (firstName || '').substr(0, 2);
			}
			return (
			  (firstName || '').substr(0, 1).toUpperCase() +
			  (lastName || '').substr(0, 1).toUpperCase()
			);
		}
		
	}
  },
  methods: {
	  dateFormat(date) {
	    if (!date) {
	      return "-";
	    }
	    return moment.utc(date).local().format("DD/MM/YYYY");
	  },
    menuSelect(key, item) {
      console.log(key, item);
      this.activeIndex = key;
      this.$router.push(`/${key}`);
    },
    _getData() {
      Ajax.get("/usermanage/student/selectByUserId", {
        userId: this.parentUserId,
      }).then((res) => {
        this.tableData = res.data || [];
      });
    },
    tableRowClassName(cellData) {
      if (cellData.status == "Withdrawn") {
        return "opacity50";
      }
      return "";
    },
  },
  created() {
    this._getData();
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类

.avatar-con {
  display: flex;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  width: 492px;
  .borderless-left {
    margin-right: 20px;
    min-width: 140px;
    max-width: 220px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    color: #202124;
  }
}
.info {
  color: #b4b4b4;
  font-size: 14px;
  font-family: Roboto;
  .status {
    color: #0b8043;
  }
}
.connections-content {
  border: 1px solid #e0e0e0;
  border-radius: 13px;
  overflow: hidden;

  /deep/ .edoovo-table-cell-inner {
    font-family: Roboto;
    color: #202124;
    font-size: 14px;
  }
  /deep/ .edoovo-thead .edoovo-table-cell-inner {
    font-family: OpenSans-Bold;
    color: #5f6368;
    font-size: 20px;
  }
  /deep/ .edoovo-thead .edoovo-table-cell {
    background: none;
  }
  /deep/ .edoovo-tbody-row {
    height: 88px;
  }
  /deep/.edoovo-tbody-row:last-child .edoovo-table-cell {
    border-bottom: 0;
  }

  .school-img {
    width: 80px;
    height: 40px;
    object-fit: cover;
  }
  /deep/.edoovo-tbody-row.opacity50 {
    opacity: 0.5;
    color: #b4b4b4;
  }
}
.textPhoto{
	line-height: 48px;
	    height: 48px;
	    width: 48px;
		display: block;
		    background: #ddd;
			border-radius: 50%;
			overflow: hidden;
			text-align: center;
			font-size: 16px;
			    font-family: Poppins;
			    font-weight: 500;
			    color: #ffffff;
}
/deep/.el-image img{
		height: 100%;
		object-fit: cover!important;
	}
</style>